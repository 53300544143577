<template>
	<UsersEdit />
</template>

<script>
import UsersEdit from './users-edit/UsersEdit'
export default {
	name: 'Customers',
	components: { UsersEdit },
}
</script>