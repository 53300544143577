<template>
    <vue-autosuggest
      :suggestions="filteredOptions"
      :limit="10"
      :input-props="{id:'autosuggest__input',class:'form-control', value:getCountryName() , placeholder:'Do you feel lucky?'}"
      @input="onInputChange"
      :value="getCountryName()"
    >
        
      <template slot-scope="{suggestion}" >
        <span class="my-suggestion-item" @click="selectedCountry = suggestion.item.shortCode">{{ suggestion.item.name }}</span>
      </template>
    </vue-autosuggest>
  </template>
  
  <script>
  import { VueAutosuggest } from 'vue-autosuggest'
  
  export default {
    components: {
      VueAutosuggest,
    },

    data() {
      return {
        datasuggest: [
            {"name": "Germany", "shortCode": "de"},
            {"name": "Austria", "shortCode": "at"}
        ],
        filteredOptions: [],
        limit: 10,
      }
    },
    props: {
		selectedCountry: {
			type: String,
			required: true,
		}
	},
    created() {
        
    },
    methods: {
        getCountryName(){
            let obj = this.datasuggest.find(o => o.shortCode === this.selectedCountry);
            return obj.name
        },
      onInputChange(text) {
        if (text === '' || text === undefined) {
          return
        }
  
        /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
        const filteredData = this.datasuggest.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
        this.filteredOptions = [{
          data: filteredData,
        }]
      },
    },
  }
  </script>