<template>
	<div>
		<b-form>
			<b-row>
				<b-col cols="3">
					<div class="ml-50">
						<h4 class="mb-1">Info</h4>
					</div>
					<b-modal
						id="modal-customer"
						cancel-variant="outline-secondary"
						ok-title="Save"
						cancel-title="Cancel"
						centered
						title="Kundendaten bearbeiten"
						@ok="save()"
					>
						<b-form>
							<b-row>
								<b-col cols="6">
									<b-form-group label="Firmenname" label-for="name">
										<b-form-input id="name" v-model="customerData.name" />
									</b-form-group>
								</b-col>
								<b-col cols="4">
									<b-form-group v-if="customerData.exemptVat" label="Vat Number" label-for="vatNumber">
										<b-form-input id="vatNumber" v-model="customerData.vatNumber" />
									</b-form-group>
								</b-col>
								<b-col cols="2">
									<b-form-group label="USt." label-for="exemptVat">
										<b-form-checkbox v-model="customerData.exemptVat" name="exemptVat" switch inline> </b-form-checkbox>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col cols="6">
									<b-form-group label="First Name" label-for="surename">
										<b-form-input id="surename" v-model="customerData.surename" />
									</b-form-group>
								</b-col>
								<b-col cols="6">
									<b-form-group label="Last Name" label-for="familyname">
										<b-form-input id="familyname" v-model="customerData.familyname" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-group label="Telefon" label-for="phone">
										<b-form-input id="phone" v-model="customerData.phone" type="text" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-form-group label="Main Email" label-for="email">
										<b-form-input id="email" v-model="customerData.email" type="email" />
									</b-form-group>
								</b-col>
							</b-row>
							<b-row v-for="(additionalEmail, index) in userData.additionalEmails" :key="index">
								<b-col cols="10">
									<b-form-input
										:id="'additionalEmails' + index"
										v-model="additionalEmail.email"
										class="mb-1"
										type="email"
									/>
								</b-col>
								<b-col cols="2" class="text-center">
									<b-button @click="removeAdditionalEmail(index)" variant="flat-danger" class="btn-icon rounded-circle">
										<feather-icon icon="ArchiveIcon" />
									</b-button>
								</b-col>
							</b-row>
							<b-row>
								<b-col class="text-center">
									<b-button inline @click="addAdditionalEmail()" variant="primary-outline"> Add Email </b-button>
								</b-col>
							</b-row>
						</b-form>
					</b-modal>
				</b-col>
				<b-col cols="4">
					<div class="">
						<h3 class="mb-1">
							{{ customerData.name }}
						</h3>
						<span class="mb-1"> {{ customerData.surename }} {{ customerData.familyname }} </span>
					</div>
				</b-col>
				<b-col cols="4">
					<b-row class="mb-1">
						<b-col>
							<feather-icon icon="PhoneIcon" class="mr-75" />
							<span class="font-weight-bold">{{ customerData.phone }}</span>
						</b-col>
					</b-row>
					<b-row class="mb-1">
						<b-col>
							<feather-icon icon="MailIcon" class="mr-75" />
							<span class="font-weight-bold">{{ customerData.email }}</span>
						</b-col>
					</b-row>
					<b-row v-for="(additionalEmail, index) in customerData.additionalEmails" :key="index">
						<b-col>
							<feather-icon icon="MailIcon" class="mr-75" />
							<span class="font-weight-bold">{{ additionalEmail.email }}</span>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="1" class="pl-1 text-left">
					<b-button variant="flat-primary" size="sm" v-b-modal.modal-customer class="btn-icon">
						<feather-icon icon="Edit2Icon" />
					</b-button>
				</b-col>
			</b-row>
			<hr />
			<user-edit-tab-address :user-data="customerData" class="pt-75" />
		</b-form>
	</div>
</template>

<script>
import {
	BButton,
	BModal,
	BMedia,
	BAvatar,
	BRow,
	BCol,
	BFormGroup,
	BFormInput,
	BForm,
	BTable,
	BCard,
	BCardHeader,
	BCardTitle,
	BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { mapFields } from 'vuex-map-fields'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'
import UserEditTabAddress from './UserEditTabAddress.vue'

export default {
	components: {
		BButton,
		BModal,
		BMedia,
		BAvatar,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		BForm,
		BTable,
		BCard,
		BCardHeader,
		BCardTitle,
		BFormCheckbox,
		vSelect,
		UserEditTabAddress,
	},
	props: {
		userData: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapFields('Customers', { customerData: 'currentCustomer' }),
	},
	setup(props) {
		const { resolveUserRoleVariant } = useUsersList()

		const statusOptions = [
			{ label: 'Pending', value: 'pending' },
			{ label: 'Active', value: 'active' },
			{ label: 'Inactive', value: 'inactive' },
		]

		// ? Demo Purpose => Update image on click of update
		const refInputEl = ref(null)
		const previewEl = ref(null)

		const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
			// eslint-disable-next-line no-param-reassign
			props.userData.avatar = base64
		})

		return {
			resolveUserRoleVariant,
			avatarText,
			statusOptions,
			refInputEl,
			previewEl,
			inputImageRenderer,
		}
	},
	methods: {
		addAdditionalEmail() {
			let newEmail = { email: '' }
			this.customerData.additionalEmails.push(newEmail)
		},
		removeAdditionalEmail(i) {
			this.customerData.additionalEmails.splice(i, 1)
		},
		save() {
			// this.saving = true
			const data = this.customerData
			this.$store.dispatch('Customers/updateCustomer', data).then((res) => {
				this.$store.dispatch('Customers/fetchCustomer', this.$router.currentRoute.params.id)
			})
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
