<template>
  <component :is="customerData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="customerData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="customerData && customerAddresses"
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Kunde</span>
        </template>
        <user-edit-tab-account
          :user-data="customerData"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: History -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Verlauf</span>
        </template>
        <user-edit-tab-history class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabAddress from './UserEditTabAddress.vue'
import UserEditTabHistory from './UserEditTabHistory.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabAddress,
    UserEditTabHistory,
  },
  setup() {
    const customerData = ref(null)
    const customerAddresses = {}
    
    store
			.dispatch('Addresses/fetchAddresses', router.currentRoute.params.id)
			.then((response) => {
        customerAddresses.addresses = response.data.addresses
        customerAddresses.addressesCount = response.data.total
			})
			.catch((error) => {
				if (error.response.status === 404) {
					customerAddresses.value = undefined
				}
			})

    store
			.dispatch('Customers/fetchCustomer', router.currentRoute.params.id)
			.then((response) => {
				customerData.value = response.customer
			})
			.catch((error) => {
				if (error.response.status === 404) {
					customerData.value = undefined
				}
			})
    return {
      customerData, customerAddresses,
    }
  },
}
</script>

<style>

</style>
