<template>
	<div>
		<b-row>
			<b-col cols="3">
				<div class="ml-50">
					<h4 class="mb-0">Addresses</h4>
					<b-modal
						id="modal-address-new"
						cancel-variant="outline-secondary"
						ok-title="Save"
						cancel-title="Cancel"
						centered
						title="Neue Addresse anlegen"
						@ok="newAddress()"
					>
						<b-form>
							<b-row>
								<b-col cols="12">
									<b-form-group label="Address Line 1" label-for="address-line-1">
										<b-form-input id="address-line-1" v-model="newCustomerAddress.name" />
									</b-form-group>
									<b-form-group label="Address Line 2" label-for="address-line-1">
										<b-form-input id="address-line-2" v-model="newCustomerAddress.name2" />
									</b-form-group>
									<b-form-group label="Address Line 3" label-for="address-line-1">
										<b-form-input id="address-line-3" v-model="newCustomerAddress.name3" />
									</b-form-group>
								</b-col>
								<b-col cols="9">
									<b-form-group label="Street and number" label-for="address-line-1">
										<b-form-input id="address-line-1" v-model="newCustomerAddress.street" />
									</b-form-group>
								</b-col>
								<b-col cols="3">
									<b-form-group label="Postcode" label-for="postcode">
										<b-form-input id="postcode" v-model="newCustomerAddress.zip" type="number" placeholder="597626" />
									</b-form-group>
								</b-col>
								<b-col cols="6">
									<b-form-group label="City" label-for="city">
										<b-form-input id="city" v-model="newCustomerAddress.city" />
									</b-form-group>
								</b-col>
								<!-- Field: Country -->
								<b-col cols="6">
									<b-form-group label="Country" label-for="country">
										<CountryAutoComplete :selected-country="newCustomerAddress.country.shortCode" />
									</b-form-group>
								</b-col>
								<b-col>
									<b-form-checkbox v-model="newCustomerAddress.mainAddress" name="check-button" switch inline>
										Hauptadresse
									</b-form-checkbox>
								</b-col>
							</b-row>
						</b-form>
					</b-modal>
				</div>
			</b-col>
			<b-col cols="9">
				<b-row v-for="(customerAddress, index) in addressBook.addresses" :key="index" class="mt-0">
					<b-col cols="11" class="pr-1">
						<b-card class="bg-primary bg-lighten-5 w-100 mb-1">
							<b-badge v-if="customerAddress.mainAddress" variant="primary" class="badge-glow main-badge"> Main </b-badge>
							<b-card-body class="d-flex p-0">
								<b-col cols="4">
									<b-avatar rounded size="24" class="mb-1">
										<feather-icon icon="MapPinIcon" size="16" />
									</b-avatar>
									<h5 class="mb-0">
										{{ customerAddress.name }}
									</h5>
									<small>{{ customerAddress.name2 }}</small>
									<br />
									<small>{{ customerAddress.name3 }}</small>
								</b-col>
								<b-col cols="4">
									<b-avatar rounded size="24" class="mb-1">
										<feather-icon icon="MapPinIcon" size="16" />
									</b-avatar>
									<h5>
										{{ customerAddress.street }}
									</h5>
									<small>{{ customerAddress.zip }} {{ customerAddress.city }}</small>
									<br />
									<small v-if="customerAddress.country">{{ customerAddress.country.name }} </small>
								</b-col>
							</b-card-body>
						</b-card>
					</b-col>
					<b-col cols="1" class="pl-0">
						<b-button variant="flat-primary" size="sm" v-b-modal.modal-address @click="showModal(index)" class="btn-icon">
							<feather-icon icon="Edit2Icon" />
						</b-button>
						<br />
						<b-button variant="flat-danger" size="sm" @click="deleteAddress(customerAddress._id)" class="btn-icon">
							<feather-icon icon="DeleteIcon" />
						</b-button>
					</b-col>
				</b-row>
				<b-row class="text-center mb-2">
					<b-col>
						<b-button inline size="sm" v-b-modal.modal-address-new variant="primary"> Neue Adresse hinzufügen </b-button>
					</b-col>
				</b-row>
			</b-col>
			<b-modal
				id="modal-address"
				cancel-variant="outline-secondary"
				ok-title="Save"
				cancel-title="Cancel"
				centered
				title="Adresse bearbeiten"
				@ok="updateAddress()"
			>
				<b-form v-if="selected > -1 && selected !== null">
					<b-row>
						<b-col cols="12">
							<b-form-group label="Address Line 1" label-for="address-line-1">
								<b-form-input id="address-line-1" v-model="addressBook.addresses[selected].name" />
							</b-form-group>
							<b-form-group label="Address Line 2" label-for="address-line-1">
								<b-form-input id="address-line-2" v-model="addressBook.addresses[selected].name2" />
							</b-form-group>
							<b-form-group label="Address Line 3" label-for="address-line-1">
								<b-form-input id="address-line-3" v-model="addressBook.addresses[selected].name3" />
							</b-form-group>
						</b-col>
						<b-col cols="9">
							<b-form-group label="Street and number" label-for="address-line-1">
								<b-form-input id="address-line-1" v-model="addressBook.addresses[selected].street" />
							</b-form-group>
						</b-col>
						<b-col cols="3">
							<b-form-group label="Postcode" label-for="postcode">
								<b-form-input
									id="postcode"
									v-model="addressBook.addresses[selected].zip"
									type="number"
									placeholder="597626"
								/>
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group label="City" label-for="city">
								<b-form-input id="city" v-model="addressBook.addresses[selected].city" />
							</b-form-group>
						</b-col>

						<b-col cols="6">
							<b-form-group label="Country" label-for="country">
								<CountryAutoComplete :selected-country="addressBook.addresses[selected].country.shortCode" />
							</b-form-group>
						</b-col>
						<b-col>
							<b-form-checkbox v-model="addressBook.addresses[selected].mainAddress" name="check-button" switch inline>
								Hauptadresse
							</b-form-checkbox>
						</b-col>
					</b-row>
				</b-form>
			</b-modal>
		</b-row>
	</div>
</template>

<script>
import {
	BRow,
	BBadge,
	BCard,
	BCardText,
	BModal,
	BMedia,
	BMediaAside,
	BAvatar,
	BCardTitle,
	BCardHeader,
	BCardBody,
	BCol,
	BForm,
	BFormGroup,
	BFormInput,
	BFormRadioGroup,
	BFormCheckbox,
	BFormCheckboxGroup,
	BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'
import CountryAutoComplete from '@/views/wercbrain/resources/CountryAutoComplete'
import { newCustomer } from '@/api/customerAddresses.api'

export default {
	components: {
		BRow,
		BCard,
		BCardText,
		BModal,
		BMedia,
		BMediaAside,
		BAvatar,
		BCardTitle,
		BCardHeader,
		BCardBody,
		BCol,
		BForm,
		BFormGroup,
		flatPickr,
		BFormInput,
		vSelect,
		BFormRadioGroup,
		BFormCheckbox,
		BFormCheckboxGroup,
		BBadge,
		BButton,
		CountryAutoComplete,
	},
	props: {
		userData: {
			type: Object,
			required: true,
		},
	},
	data() {
		let selected
		const newCustomerAddress = {
			name: '',
			name2: '',
			name3: '',
			street: '',
			zip: '',
			city: '',
			country: {
				shortCode: 'de',
			},
			mainAddress: false,
		}
		return {
			selected,
			newCustomerAddress,
		}
	},

	computed: {
		addressBook() {
			return this.$store.getters['Addresses/getAddresses']
		},
	},
	methods: {
		showModal(i) {
			console.log(i)
			this.selected = i
		},
		updateAddress() {
			let data = this.addressBook.addresses[this.selected]
			this.$store
				.dispatch('Addresses/updateAddress', data)
				.then((res) => {
					this.$store.dispatch('Addresses/fetchAddresses', router.currentRoute.params.id)
				})
				.finally(() => {
					setTimeout(() => (this.saving = false), 500)
				})
		},
		newAddress() {
			const data = this.newCustomerAddress
			data['customerId'] = router.currentRoute.params.id
			this.$store
				.dispatch('Addresses/newAddress', data)
				.then((res) => {
					this.$store.dispatch('Addresses/fetchAddresses', router.currentRoute.params.id)
				})
				.finally(() => {
					setTimeout(() => (this.saving = false), 500)
				})
		},
		deleteAddress(i) {
			console.log(i)
			this.selected = null
			this.$store.dispatch('Addresses/deleteAddress', i).then((res) => {
				this.$store.dispatch('Addresses/fetchAddresses', router.currentRoute.params.id)
			})
		},
	},
}
</script>

<style lang="sass">

.main-badge
  position: absolute
  top: 0
  left: -10px
  transform: rotate(-15deg)
</style>
